import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Popper from '@material-ui/core/Popper';
import * as React from 'react';

interface IDocPreview {
  open: boolean;
  anchorEl: any;
  docData: any;
};

/**
 * Composant qui affiche l'aperçu du document survolé
 * @param props:IDocPreview
 * @returns JSX.ELement
 */
const DocPreview = (props: IDocPreview) => {
  const baseUrl = "_layouts/15/getpreview.ashx?resolution=6&path=";
  let docUrl = "";
  // https://yes31.sharepoint.com/_layouts/15/getpreview.ashx?resolution=6&path=https://yes31.sharepoint.com/Documents%20partages/Feuille%20de%20conges.docx
  if(props.docData != undefined && props.docData != null) {
    if(props.docData.finishDoc != undefined) {
      const siteUrl = props.docData.finishDoc.site.slice(-1) == "/" ? props.docData.finishDoc.site : props.docData.finishDoc.site + "/";
      docUrl = siteUrl + baseUrl + props.docData.finishDoc.editFileRef;
    }
    else if(props.docData.copyDocEdit != undefined) {
      const siteUrl = props.docData.copyDocEdit.site.slice(-1) == "/" ? props.docData.copyDocEdit.site : props.docData.copyDocEdit.site + "/";
      docUrl = siteUrl + baseUrl + props.docData.copyDocEdit.editFileRef;
    }
    else {
      docUrl = docUrl;
    }
  }
  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      placement='right'
      style={{ marginLeft: "20px" }}
    >
      <Card>
        <CardContent style={{ width: "300px", padding: "10px", minHeight: "150px", height: "auto" }}>
          <img
            loading="lazy"
            alt="Aperçu du document"
            style={{ width: "100%", height: "auto", aspectRatio: "auto" }}
            src={docUrl}
          />
        </CardContent>
      </Card>
    </Popper>
  );
}

export default DocPreview;