import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import Store from './store/store';
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import ParametersUser from "./parametersUsers/ListUsers";
import Dashboard from "./dashboard/Dashboard";
import { observer } from "mobx-react";
import Form from "../components/FormulaireReferencement/Form";
import Parameter from "./parameters/param";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AllTasksUsers from "./Workflow/AllTasksUsers";
import Models from "./parametersModels/ListContainer";
import Roles from "./parametersRoles/ListRoles";
import MyMessages from "./parametersMessages/MyMessages";
import Search from "./search/searchWorkflow";
import Me from "./Menus/Me";
import HistoricActions from "./Workflow/HistoricActions";
import parameterStartAuto from "./parametersAutoStart/parameterAutoStart";
import SnackBar from "./SnackBar/SnackBar";
import refresh from "./refresh/refresh";
import Delegation from "./delegation/delegation";
import ListGroupes from "./parametersGroups/ListGroupes";
import FormsList from "./forms/formsList/FormsList";
import CreateForm from "./forms/createForm/CreateForm";
import FicheDePoste from "./forms/ficheDePoste/FicheDePoste";
import Email from "./Email/Email";
import { initializeIcons } from '@uifabric/icons';
import EmailContentEditor from "./Email/EmailContentEditor";
import Login from "./Login";
import Error from "./errorComponent/Error";
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
initializeIcons("https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/icons/");
initializeFileTypeIcons("https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/item-types/");


const theme = createMuiTheme({
  overrides: {
    // Name of the component ⚛️ / style sheet
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 14px) scale(1)"
      },
      shrink: {
        transform: "translate(14px, -6px) scale(0.75)"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "11px 14px"
      }
    },
    MuiTabs: {
      root: {
        minHeight: "auto"
      },
      indicator: {
        backgroundColor: Store.themeParam.color
      }
    },
    MuiTab: {
      root: {
        minHeight: "auto",
        minWidth: "auto !important",
        background: Store.themeParam.backgroundPrimary,
        color: Store.themeParam.color + " !important"
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: "auto !important",
        backgroundColor: "#eee"
      },
      content: {
        margin: "0 !important"
      }
    },
    MuiToolbar: {
      regular: {
        minHeight: "55px !important"
      }
    }
  },
  palette: {
    primary: {
      main: Store.themeParam.backgroundSecondary,
      contrastText: Store.themeParam.color
    }
  },
  typography: {
  }
});
@observer
class App extends React.Component<RouteComponentProps, any> {
  public constructor(props: any) {
    super(props);
    this.state = {
      keyNameSessionLink: "lyflowStartLink"
    }

    // Récupération de l'url avant authentification pour redirection après authentification
    // Mise en cache dans le SessionStorage
    let searchParams: string = this.props.location.search;
    if (searchParams.startsWith("?ssoToken=") == true) {
      searchParams = "";
    }
    else if (this.props.location.pathname.startsWith("/login") == true) {
      const startLink: string = "/";
      sessionStorage.setItem(this.state.keyNameSessionLink, startLink);
    }
    else {
      const startLink: string = this.props.location.pathname + searchParams;
      sessionStorage.setItem(this.state.keyNameSessionLink, startLink);
    }
  }

  public render(): JSX.Element {
    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          <ProtectedRoutes exact path="/" menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} component={Dashboard} />
          <ProtectedRoutes exact menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/document" component={Form} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/users" isAdmin={true} component={ParametersUser} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/models" isAdmin={true} component={Models} />
          <ProtectedRoutes exact menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/parameterStartAuto" isAdmin={true} component={parameterStartAuto} />
          <ProtectedRoutes exact menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/parameters" isAdmin={true} component={Parameter} />
          <ProtectedRoutes exact menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/mytasks" component={AllTasksUsers} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/search" component={Search} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/roles" isAdmin={true} component={Roles} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/mymessage" component={MyMessages} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/mydashboard" component={Dashboard} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/myaccount" component={Me} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/delegation" component={Delegation} />
          <ProtectedRoutes path="/HistoricActions/:idDoc" menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} component={HistoricActions} />
          <ProtectedRoutes exact path="/refresh" menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} component={refresh} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/groups" isAdmin={true} component={ListGroupes} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/forms" component={FormsList} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/createForm" component={CreateForm} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/fichedeposte" component={FicheDePoste} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/email" isAdmin={true} component={Email} />
          <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/emailContent" isAdmin={true} component={EmailContentEditor} />
          <Route exact path="/login" children={<Login keyNameSessionLink={this.state.keyNameSessionLink} />} />
          <Route path="/login/:error" children={<Login keyNameSessionLink={this.state.keyNameSessionLink} />} />
          <Route path="*" children={<Error errorTitle="404" errorMessage="Page not found" />} />
        </Switch>
        <SnackBar />
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);